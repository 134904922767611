<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="">
        <!-- <el-button class="right" style="width: 88px; height: 36px; margin-top: -46px;" @click="$router.go(-1)">返回</el-button> -->
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader ref="cascader" @change="parkClear"></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <a-operation-select ref="operationSelect" @change="parkClear"></a-operation-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <a-park-type-select ref="parkTypeSelect" @change="parkClear"></a-park-type-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.region')">
          <el-select v-model.trim="formInline.areaId" @change="areaChange" filterable size="15" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option :label="area.areaName" :value="area.areaId" :key="area.areaId" v-for="area in areaList"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.region')">
          <el-select v-model.trim="formInline.streetId" filterable size="15" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option :label="area.areaName" :value="area.areaId" :key="area.areaId" v-for="area in areaList2"></el-option>
          </el-select>
        </el-form-item> -->
              <el-form-item :label="$t('searchModule.park_name')">
                <a-park-select
                  ref="parkSelect"
                  :instance="this"
                  parkTypeRefName="parkTypeSelect"
                  operationRefName="operationSelect"
                  areaRefName="cascader"
                ></a-park-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.charge_type')">
                <el-select v-model.trim="formInline.scopeId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="一类区" value="1"></el-option>
                  <el-option label="二类区" value="2"></el-option>
                  <el-option label="三类区" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Date_search')">
                <a-date-picker ref="datePicker" :selectkeys="selectkeys"></a-date-picker>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.date')" prop="date">
          <el-date-picker
            v-model="formInline.date"
            :picker-options="pickerOptions"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item> -->
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchData()"
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >

              <el-button type="info" icon="el-icon-delete" @click="empty()" :loading="loading"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
              <el-button
                type="info"
                icon="el-icon-upload2"
                @click="exportExcelReport"
                :loading="loading"
                v-if="$route.meta.authority.button.export"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <div class="content">
        <div class="graphShadow grid-content bg-purple paddingT10 paddingLR20 marginTB20">
          <graphTitle :title="'临时停车次数占比'">
            <el-tooltip
              placement="top"
              content="展示所选日期的临时停车占全部停车记录的百分比。临时停车为停车时长小于15分钟的停车记录"
            >
              <i class="el-icon-question my-icon" />
            </el-tooltip>
          </graphTitle>
          <CircularChart class="paddingB10" :temporaryParking="temporaryParking" :type="type3">
            <template slot-scope="scores">
              <h2 style="text-align: center; font-size: 18px">{{ scores.totalDegree }}</h2>
            </template>
          </CircularChart>
        </div>
        <CircularChartFold
          class="graphShadow grid-content paddingT10 paddingLR20 marginT20"
          :temporaryParktableData="tableData"
          :type="type2"
        ></CircularChartFold>
        <el-dialog title="导出" :visible.sync="dialogVisible" width="35%">
          <el-row>
            <h1>日报表</h1>
            <el-date-picker
              v-model="startTimeDay"
              type="date"
              :picker-options="pickerOptions"
              placeholder="选择日期"
            ></el-date-picker
            >至<el-date-picker
              v-model="endTimeDay"
              type="date"
              :picker-options="pickerOptions"
              placeholder="选择日期"
            ></el-date-picker>
            <el-button type="text" @click="exportExcelReport('1')">下载</el-button>
          </el-row>
          <el-row>
            <h1>月报表</h1>
            <el-date-picker
              v-model="startTimeMonth"
              type="month"
              :picker-options="pickerOptions"
              placeholder="选择日期"
            ></el-date-picker
            >至<el-date-picker
              v-model="endTimeMonth"
              type="month"
              :picker-options="pickerOptions"
              placeholder="选择日期"
            ></el-date-picker>
            <el-button type="text" @click="exportExcelReport('2')">下载</el-button>
          </el-row>
          <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
// 饼形图
import CircularChart from "../../../components/circularChart/circularChart5";
// 折线图
import CircularChartFold from "./circularChart4";
import graphTitle from "@/components/graphTitle";
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  name: "temporaryPark",
  data() {
    const newDate = new Date();
    newDate.setTime(newDate.getTime() - 3600 * 1000 * 24);
    return {
      // 区域列表
      selectkeys: ["date", "month", "week", "year"],
      areaList: [],
      // 区域街道
      areaList2: [],
      tableData: [],
      // 计费类型
      billingTypes: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > newDate;
        },
      },
      type2: "c2",
      type3: "c3",
      loading: false,
      dialogVisible: false,
      startTimeDay: "",
      endTimeDay: "",
      startTimeMonth: "",
      endTimeMonth: "",
      // 临时停车占比图
      temporaryParking: [],
      formInline: {
        // 区域
        areaId: "",
        // 区域
        streetId: "",
        // 车场ID
        parkId: "",
        // 车场名称
        parkName: "",
        // 计费类型
        scopeId: "",
        startDate: "",
        endDate: "",
        dateType: "",
      },
    };
  },
  methods: {
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    empty() {
      this.areaId = "";
      this.streetId = "";
      this.formInline.scopeId = "";
      this.$refs.cascader.clear();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
      this.$refs.parkSelect.clear();
    },
    areaChange(item) {
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    // 初始化用户区域下拉列表（区域和街道）
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    dateType() {
      let dateStr = "";
      if (this.formInline.date) {
        dateStr = this.dateFormat(this.formInline.date, "yyyy-MM-dd");
      } else {
        dateStr = "";
      }
      return dateStr;
    },
    // 格式化日期
    dateFormat(date, fmt) {
      if (!(date instanceof Date)) {
        return "";
      }
      fmt = fmt || "yyyy-MM-dd HH:mm:ss";
      var o = {
        // 月份
        "M+": date.getMonth() + 1,
        // 日
        "d+": date.getDate(),
        // 小时
        "H+": date.getHours(),
        // 分
        "m+": date.getMinutes(),
        // 秒
        "s+": date.getSeconds(),
        // 季度
        "q+": Math.floor((date.getMonth() + 3) / 3),
        // 毫秒
        S: date.getMilliseconds(),
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    // 计费类型
    billingFn() {
      this.$axios.get("/acb/2.0/systems/loginUser/initScopeList").then((res) => {
        this.billingTypes = res.value.scopeList;
      });
    },
    // 时间判断
    showLog() {
      if (this.formInline.dateType === "1") {
        if (this.startTimeDay && this.endTimeDay) {
          this.formInline.startDate = dateFormat(this.startTimeDay, "yyyy-MM-dd");
          this.formInline.endDate = dateFormat(this.endTimeDay, "yyyy-MM-dd");
        } else {
          this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        }
        if (this.formInline.startDate > this.formInline.endDate) {
          this.$alert("结束时间不能小于开始时间", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        } else {
          return true;
        }
      } else if (this.formInline.dateType === "2") {
        if (this.startTimeMonth && this.endTimeMonth) {
          this.formInline.startDate = dateFormat(this.startTimeMonth, "yyyy-MM");
          this.formInline.endDate = dateFormat(this.endTimeMonth, "yyyy-MM");
        } else {
          this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        }
        if (this.formInline.startDate > this.formInline.endDate) {
          this.$alert("结束时间不能小于开始时间", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        } else {
          return true;
        }
      }
    },
    // 查询
    searchData(Num) {
      this.loading = true;
      this.temporaryParking = [];
      this.$axios
        .get("/acb/2.0/shortPark/shortParkStatistics", {
          data: {
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            scopeId: this.formInline.scopeId,
          },
        })
        .then((res) => {
          let { longParkNum, shortParkNum, totalParkNum, parkTrend } = res.value;
          this.temporaryParking.push({
            longParkNum: longParkNum != null ? longParkNum : 0,
            shortParkNum: shortParkNum != null ? shortParkNum : 0,
            totalParkNum: totalParkNum != null ? totalParkNum : 0,
          });
          this.tableData = parkTrend;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 导出
    exportExcelReport(Num) {
      this.formInline.dateType = Num;
      let data = JSON.parse(JSON.stringify(this.formInline));
      data.streetId = this.$refs.cascader ? this.$refs.cascader.getStreetId() : "";
      data.areaId = this.$refs.cascader ? this.$refs.cascader.getAreaId() : "";
      data.dataSource = this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "";
      data.operationId = this.$refs.operationSelect
        ? this.$refs.operationSelect.getOperationId()
        : "";
      data.parkId = this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "";
      data.dateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      data.startDate = this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "";
      data.endDate = this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "";
      data.scopeId = this.formInline.scopeId;
      console.log(data.startDate);
      console.log(data.endDate);
      // this.dialogVisible = false;
      exportExcelNew("/acb/2.0/shortPark/shortParkStatisticsExport", data, "post");
    },
  },
  mounted() {
    this.searchData();
  },
  created() {
    this.getArea();
    // this.billingFn();
  },
  components: {
    CircularChartFold,
    CircularChart,
    graphTitle,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
h1
  margin 20px 0 10px 0
</style>
